<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 col-md-8">
        <h1 class="title-w1" style="margin-bottom: 2rem;">Tool Options</h1>
        <p>
          Welcome to the Circular Data Centre Compass (CDCC). Choose from the
          following tool options: Compare, Ecodesign Evaluator and End-of-life
          to assess your Data Centre equipment at various stages of its life.
        </p>
        <p>
          All options were developed in-line with
          <span class="text-bold"
            >the EU Circular Economy Action Plan 2020 </span
          >and other eco-design directives and regulations as well as the
          <span class="text-bold">empirical data collected by CEDaCI</span> from
          the material breakdown and assessment of various server models.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Compare</h3>
            <p class="card-text">
              Compare the specifications and environmental, social and economic
              impact of two servers based on a chosen configuration and generate
              a free PDF report.
            </p>
            <div class="btn-right">
              <router-link :to="{ name: 'Compare' }">
                <el-button type="primary">Start</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">End-Of-Life</h3>
            <p class="card-text">
              Explore end-of-life options for a given server and choose the most
              beneficial outcome from a social, economic, and environmental
              perspective.
            </p>
            <div class="btn-right">
              <router-link :to="{ name: 'EndOfLife' }">
                <el-button type="primary">Start</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Ecodesign Evaluator</h3>
            <p class="card-text">
              Check the circularity of your server design in compliance with
              Ecodesign and Design for Circularity guidelines.
            </p>
            <div class="btn-right">
              <router-link :to="{ name: 'Evaluate' }">
                <el-button type="primary">Start</el-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Compass',
};
</script>